import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { companyName } from "../site/constants"

const useCompanyInfo = () => {
  const data = useStaticQuery(graphql`
    query getEmailPhone {
      glstrapi {
        siteConfig {
          ...emailPhone
        }
      }
    }
  `)

  const { phone, email } = data?.glstrapi?.siteConfig

  return { name: companyName, phone, email }
}

export default useCompanyInfo
